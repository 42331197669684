import * as z from 'zod';

export const InitialBalances = z.object({
  interest: z.number(),
  escrow: z.number(),
  principal: z.number(),
  principalPrepayment: z.number(),
  suspense: z.number().optional(),
  reserve: z.number().optional(),
  mortgageInsurance: z.number().optional(),
  fee: z.number().optional(),
  holdback: z.number().optional(),
});
export type InitialBalances = z.infer<typeof InitialBalances>;
