import * as z from 'zod';

import { PhoneNumber } from '../../Phone';
import {
  Checkbox,
  DateString,
  PositiveInteger,
  PositiveMonetaryValue,
  StateAbbreviation,
  Zipcode,
} from '../../validations/fields';
import { OccupancyTypeEnum } from '../../validations/loan/CreateLoanRow';

export const Form1098Year = z.enum(['2022', '2023', '2024']);
export type Form1098Year = z.infer<typeof Form1098Year>;

export const Form1098Validator2022 = z.object({
  year: z.literal(Form1098Year.Enum['2022']),
  isCorrected: Checkbox,
  accountNumber: z.string(),

  lenderTINPresent: Checkbox,
  lenderName: z.string(),
  lenderPhone: PhoneNumber,
  lenderAddressLine1: z.string(),
  lenderAddressLine2: z.string().optional(),
  lenderAddressLine3: z.string().optional(),
  lenderAddressLine4: z.string().optional(),
  lenderAddressLocality: z.string(),
  lenderAddressRegion: StateAbbreviation,
  lenderAddressPostcode: Zipcode,
  lenderAddressCountry: z.string(),

  borrowerSSNPresent: Checkbox,
  borrowerId: z.string(),

  entityId: z.string().optional(),
  entityEIN: z.string().optional(),

  borrowerName: z.string(),
  borrowerAddressLine1: z.string(),
  borrowerAddressLine2: z.string().optional(),
  borrowerAddressLine3: z.string().optional(),
  borrowerAddressLine4: z.string().optional(),
  borrowerAddressLocality: z.string(),
  borrowerAddressRegion: StateAbbreviation,
  borrowerAddressPostcode: Zipcode,
  borrowerAddressCountry: z.string(),

  occupancyType: OccupancyTypeEnum.optional(),

  box1InterestReceived: PositiveMonetaryValue,
  box2OutstandingMortgagePrincipal: PositiveMonetaryValue,
  box3MortgageOriginationDate: DateString,
  box4RefundOfOverpaidInterest: PositiveMonetaryValue,
  box5MortgageInsurancePremiums: PositiveMonetaryValue,
  box6PointsPaid: PositiveMonetaryValue,
  box7PropertyAddressSameAsBorrowerAddress: Checkbox,
  box8PropertyAddressLine1: z.string().optional(),
  box8PropertyAddressLine2: z.string().optional(),
  box8PropertyAddressLine3: z.string().optional(),
  box8PropertyAddressLine4: z.string().optional(),
  box8PropertyAddressLocality: z.string().optional(),
  box8PropertyAddressRegion: StateAbbreviation.optional().or(z.literal('')),
  box8PropertyAddressPostcode: Zipcode.optional(),
  box8PropertyAddressCountry: z.string().optional(),
  box9NumberOfProperties: PositiveInteger,
  box10Other: z.string().optional(), // Leaving this super flexible bc what it's for is unclear
  box11AcquisitionDate: DateString.optional(),
});
type Form1098Validator2022 = z.infer<typeof Form1098Validator2022>;

export const Form1098Validator2023 = Form1098Validator2022.omit({ year: true }).extend({
  year: z.literal(Form1098Year.Enum['2023']),
});
type Form1098Validator2023 = z.infer<typeof Form1098Validator2023>;

export const Form1098Validator2024 = Form1098Validator2022.omit({ year: true }).extend({
  year: z.literal(Form1098Year.Enum['2024']),
});
type Form1098Validator2024 = z.infer<typeof Form1098Validator2024>;

// Make this a discriminated union on type when we have another year. It may need more reworking than that.
export const Form1098Validator = z.discriminatedUnion('year', [
  Form1098Validator2022,
  Form1098Validator2023,
  Form1098Validator2024,
]);
export type Form1098Validator = z.infer<typeof Form1098Validator>;

export const Form1098Partial = z.union([
  Form1098Validator2022.partial(),
  Form1098Validator2023.partial(),
  Form1098Validator2024.partial(),
]);
export type Form1098Partial = z.infer<typeof Form1098Partial>;

const FORM_1098_DEFAULTS_2022: Pick<
  Form1098Validator2022,
  | 'isCorrected'
  | 'box4RefundOfOverpaidInterest'
  | 'box5MortgageInsurancePremiums'
  | 'box6PointsPaid'
  | 'box7PropertyAddressSameAsBorrowerAddress'
> = {
  isCorrected: false,
  box4RefundOfOverpaidInterest: 0,
  box5MortgageInsurancePremiums: 0,
  box6PointsPaid: 0,
  box7PropertyAddressSameAsBorrowerAddress: true,
} as const;

export const FORM_1098_DEFAULTS: Record<Form1098Year, any> = {
  '2022': FORM_1098_DEFAULTS_2022,
  '2023': FORM_1098_DEFAULTS_2022,
  '2024': FORM_1098_DEFAULTS_2022,
} as const;
