import * as z from 'zod';

import { LoanCreditReportId, LoanId, LosId } from './BrandedIds';
import { LoanStatus } from './loan/LoanStatus';
import { zodBrandedUuid, zodDateOrString } from './utils';

export const CREDIT_REPORTING_STATUSES: LoanStatus[] = [
  'active',
  'pendingTransfer',
  'transferred',
  'paidOff',
  'foreclosure',
] as const;

export const MonthYear = z.object({
  month: z.coerce.number().int().min(1).max(12),
  year: z.coerce.number().int().min(2000).max(2100),
});
export type MonthYear = z.infer<typeof MonthYear>;

export const CreditReportField = z.object({
  id: z.string(),
  name: z.string(),
  value: z.string(),
});
export type CreditReportField = z.infer<typeof CreditReportField>;

export const CreditReportSegment = z.object({
  name: z.string(),
  value: z.string(),
  fields: CreditReportField.array(),
});
export type CreditReportSegment = z.infer<typeof CreditReportSegment>;

export const CreditReport = z.object({
  content: z.string(),
  segments: CreditReportSegment.array(),
});
export type CreditReport = z.infer<typeof CreditReport>;

export const LoanCreditReport = z.object({
  id: zodBrandedUuid<LoanCreditReportId>(),
  loanId: zodBrandedUuid<LoanId>(),
  losId: zodBrandedUuid<LosId>(),
  cycle: MonthYear,
  report: CreditReport,
  createdAt: zodDateOrString,
});
export type LoanCreditReport = z.infer<typeof LoanCreditReport>;
