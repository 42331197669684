import { z } from 'zod';

import { BorrowerId, PrincipalModificationId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const GeneratePrincipalModificationCancelledSideEffectContext = BaseLoanIdSideEffect.extend({
  borrowerIds: z.array(zodBrandedUuid<BorrowerId>()),
  principalModificationId: zodBrandedUuid<PrincipalModificationId>(),
});

export const GeneratePrincipalModificationCancelledSideEffect =
  GeneratePrincipalModificationCancelledSideEffectContext.extend({
    type: z.literal('generatePrincipalModificationCancelled'),
  });
export type GeneratePrincipalModificationCancelledSideEffect = z.infer<
  typeof GeneratePrincipalModificationCancelledSideEffect
>;
