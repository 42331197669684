import * as z from 'zod';

import { ApiKeyId, CompanyId, ExternalEntityId, LoanImportId, LoanImportRowId, LosId, UserId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { CreateLoanRow } from '../validations/loan/CreateLoanRow';
import { UpdateLoanRow } from '../validations/loan/UpdateLoanRow';
import { CreateTransactionRowData } from './LoanTransaction';
import { TransferLoanRowData } from './LoanTransfer';

export const ImportStatus = z.enum(['PENDING', 'COMPLETED', 'FAILED']);
export type ImportStatus = z.infer<typeof ImportStatus>;

export const ImportType = z.enum([
  'create',
  'transfer',
  'createTransaction',
  'update',
  'recordPayment',
  'constructionToPermanent',
  'recordEscrowDiscursement',
  'recordDrawDisbursement',
  'updateBorrowerAchDetails',
  'updateIdsAndRemittanceData',
  'managePointOfContact',
]);
export type ImportType = z.infer<typeof ImportType>;

export const ImportSource = z.enum(['csv', 'api', 'csvV2']);
export type ImportSource = z.infer<typeof ImportSource>;

export const ImportFile = z.enum(['csv', 'xlsx']);
export type ImportFile = z.infer<typeof ImportFile>;

export interface LoanImport {
  id: LoanImportId;
  companyId: CompanyId;
  userId: UserId;
  status: ImportStatus;
  spaceId?: string;
  fileName: string;
  totalRows: number;
  createdAt: Date;
  updatedAt: Date;
  type: ImportType;
  createdRowsUrl?: string;
  // TODO LESLIE 11/3: Drop default in prisma after migration
  source: ImportSource;
  apiKeyId?: ApiKeyId;
  apiRequestBody?: string;
}

export interface LoanImportRow {
  id: LoanImportRowId;
  loanImportId: LoanImportId;
  rowNumber: number;
  losId: LosId;
  status: ImportStatus;
  reason?: string;
  createdAt: Date;
  updatedAt: Date;
}

// THINGS TO DO WITH THE RAW IMPORT ROW
// First, take the initial object parser
// from the spreadsheet uploader or API — ie, CreateLoanRow.
// Then...
// * Turn date strings into Date
// * Turn ids into branded IDs
// * Trim/strip strings (in the ImportRowsData response below)

// We have to ignore this next line because of a "Type instantiation too deep" error
// @ts-ignore
export const ImportRow = CreateLoanRow.extend({
  loanId: z.string().transform((id) => id as LosId),
  fundingDate: zodDateOrString,
  loanClosingDate: zodDateOrString.optional(),
  repurchasedDate: zodDateOrString.optional(),
  acquisitionDate: zodDateOrString.optional(),
  loanMaturityDate: zodDateOrString,
  firstCollectedPaymentDate: zodDateOrString.optional(),
  firstPaymentDate: zodDateOrString,
  entityCompanyId: z
    .string()
    .transform((id) => id as ExternalEntityId)
    .optional(),
  escrow1PolicyStart: zodDateOrString.optional(),
  escrow1PolicyEnd: zodDateOrString.optional(),
  escrow2PolicyStart: zodDateOrString.optional(),
  escrow2PolicyEnd: zodDateOrString.optional(),
  escrow3PolicyStart: zodDateOrString.optional(),
  escrow3PolicyEnd: zodDateOrString.optional(),
  mersRegistrationDate: zodDateOrString.optional(),
});

// NOTE: this must be kept in sync with the type definition above
// We cannot do export type ImportRow = z.infer<typeof ImportRow>;
// because of the "Type instantiation too deep" error -- it comes through as any :(
export type ImportRow = Omit<
  CreateLoanRow,
  | 'loanId'
  | 'fundingDate'
  | 'loanClosingDate'
  | 'repurchasedDate'
  | 'acquisitionDate'
  | 'loanMaturityDate'
  | 'firstCollectedPaymentDate'
  | 'firstPaymentDate'
  | 'entityCompanyId'
  | 'escrow1PolicyStart'
  | 'escrow1PolicyEnd'
  | 'escrow2PolicyStart'
  | 'escrow2PolicyEnd'
  | 'escrow3PolicyStart'
  | 'escrow3PolicyEnd'
  | 'drawExpirationDate'
  | 'repayBeginDate'
  | 'mersRegistrationDate'
> & {
  loanId: LosId;
  fundingDate: Date;
  loanClosingDate?: Date;
  repurchasedDate?: Date;
  acquisitionDate?: Date;
  loanMaturityDate: Date;
  firstCollectedPaymentDate?: Date;
  firstPaymentDate: Date;
  entityCompanyId?: ExternalEntityId;
  escrow1PolicyStart?: Date;
  escrow1PolicyEnd?: Date;
  escrow2PolicyStart?: Date;
  escrow2PolicyEnd?: Date;
  escrow3PolicyStart?: Date;
  escrow3PolicyEnd?: Date;
  drawExpirationDate?: Date;
  repayBeginDate?: Date;
  mersRegistrationDate?: Date;
};

const LoanImportBase = z.object({
  id: zodBrandedUuid<LoanImportId>(),
  companyId: zodBrandedUuid<CompanyId>(),
  userId: zodBrandedUuid<UserId>(),
  status: ImportStatus,
  fileName: z.string(),
  totalRows: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  source: ImportSource,
  apiKeyId: zodBrandedUuid<ApiKeyId>().optional(),
});
const LoanImportCreate = LoanImportBase.extend({
  type: z.literal(ImportType.Values.create),
  apiRequestBody: ImportRow.array().optional(),
});

const LoanImportTransfer = LoanImportBase.extend({
  type: z.literal(ImportType.Values.transfer),
  apiRequestBody: TransferLoanRowData(false).array().optional(),
});

const LoanImportTransaction = LoanImportBase.extend({
  type: z.literal(ImportType.Values.createTransaction),
  apiRequestBody: CreateTransactionRowData.array().optional(),
});

const LoanImportUpdate = LoanImportBase.extend({
  type: z.literal(ImportType.Values.update),
  apiRequestBody: UpdateLoanRow.array().optional(),
});

export const LoanImportTyped = z.discriminatedUnion('type', [
  LoanImportCreate,
  LoanImportTransfer,
  LoanImportTransaction,
  LoanImportUpdate,
]);
