import { z } from 'zod';

import { BorrowerId, DocumentId, PaymentId, PayoffId, PrincipalModificationId } from '../BrandedIds';
import { EmailAttachment } from '../EmailAttachment';
import {
  BaseEmailTemplateType,
  BaseEmailWithAttachmentTemplateType,
  DocumentEmailTemplateType,
  GoodbyeBeforeWelcome,
  GoodbyeEmail,
  LenderPaymentFailedEmail,
  PartialPayoffConfirmationEmail,
  PaymentEmailTemplateType,
  PaymentFailedAutopayEmail,
  PrincipalModificationAppliedEmail,
  PrincipalModificationCancelledEmail,
  UpdateAutopayEmailTemplateType,
} from '../emails/Templates';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const SendEmailOptions = z
  .object({
    disableEmail: z.boolean().optional(),
    disableDocument: z.boolean().optional(),
    disableAuditLog: z.boolean().optional(),
  })
  .optional();

const SendBaseEmailEvent = BaseLoanEvent.extend({
  type: z.literal('sendEmail'),
  borrowerId: zodBrandedUuid<BorrowerId>(),
  template: BaseEmailTemplateType,
  opts: SendEmailOptions,
});
type SendBaseEmailEvent = z.infer<typeof SendBaseEmailEvent>;

const SendGoodbyeEmailEvent = SendBaseEmailEvent.extend({
  template: z.union([GoodbyeEmail, GoodbyeBeforeWelcome]),
  isUpdate: z.boolean().optional(),
});
type SendGoodbyeEmailEvent = z.infer<typeof SendGoodbyeEmailEvent>;

const SendEmailWithAttachmentEvent = SendBaseEmailEvent.extend({
  template: BaseEmailWithAttachmentTemplateType,
  attachments: z.array(EmailAttachment),
});
type SendEmailWithAttachmentEvent = z.infer<typeof SendEmailWithAttachmentEvent>;

const SendPaymentEmailEvent = SendBaseEmailEvent.extend({
  template: PaymentEmailTemplateType,
  paymentId: zodBrandedUuid<PaymentId>(),
});
type SendPaymentEmailEvent = z.infer<typeof SendPaymentEmailEvent>;

const SendPaymentFailedEmailEvent = SendBaseEmailEvent.extend({
  template: PaymentFailedAutopayEmail,
  paymentId: zodBrandedUuid<PaymentId>().optional(),
  failureReason: z.string().optional(),
});
type SendPaymentFailedEmailEvent = z.infer<typeof SendPaymentFailedEmailEvent>;

const SendDwollaUpdateAutopayEmailEvent = SendBaseEmailEvent.extend({
  paymentProvider: z.literal('dwolla'),
  template: UpdateAutopayEmailTemplateType,
  autopayBankUrl: z.string(),
});
const SendUsioUpdateAutopayEmailEvent = SendBaseEmailEvent.extend({
  paymentProvider: z.literal('usio'),
  template: UpdateAutopayEmailTemplateType,
  confirmationId: z.string(),
});
const SendUpdateAutopayEmailEvent = z.union([SendDwollaUpdateAutopayEmailEvent, SendUsioUpdateAutopayEmailEvent]);
type SendUpdateAutopayEmailEvent = z.infer<typeof SendUpdateAutopayEmailEvent>;

const SendDocumentEmailEvent = SendBaseEmailEvent.extend({
  template: DocumentEmailTemplateType,
  documentId: zodBrandedUuid<DocumentId>(),
});
type SendDocumentEmailEvent = z.infer<typeof SendDocumentEmailEvent>;

const SendLenderPaymentFailedEvent = SendBaseEmailEvent.extend({
  template: LenderPaymentFailedEmail,
  paymentId: zodBrandedUuid<PaymentId>().optional(),
  failureReason: z.string().optional(),
});
type SendLenderPaymentFailedEvent = z.infer<typeof SendLenderPaymentFailedEvent>;

export const SendPartialPayoffConfirmationEmailEvent = SendBaseEmailEvent.extend({
  template: PartialPayoffConfirmationEmail,
  payoffId: zodBrandedUuid<PayoffId>(),
});
type SendPartialPayoffConfirmationEmailEvent = z.infer<typeof SendPartialPayoffConfirmationEmailEvent>;

export const SendPrincipalModificationAppliedEmailEvent = SendBaseEmailEvent.extend({
  template: PrincipalModificationAppliedEmail,
  principalModificationId: zodBrandedUuid<PrincipalModificationId>(),
});
type SendPrincipalModificationAppliedEmailEvent = z.infer<typeof SendPrincipalModificationAppliedEmailEvent>;

export const SendPrincipalModificationCancelledEmailEvent = SendBaseEmailEvent.extend({
  template: PrincipalModificationCancelledEmail,
  principalModificationId: zodBrandedUuid<PrincipalModificationId>(),
});
type SendPrincipalModificationCancelledEmailEvent = z.infer<typeof SendPrincipalModificationCancelledEmailEvent>;

export const SendEmailEvent = z.union([
  SendBaseEmailEvent,
  SendGoodbyeEmailEvent,
  SendEmailWithAttachmentEvent,
  SendPaymentEmailEvent,
  SendPaymentFailedEmailEvent,
  SendUpdateAutopayEmailEvent,
  SendDocumentEmailEvent,
  SendLenderPaymentFailedEvent,
  SendPartialPayoffConfirmationEmailEvent,
  SendPrincipalModificationAppliedEmailEvent,
  SendPrincipalModificationCancelledEmailEvent,
]);
export type SendEmailEvent = z.infer<typeof SendEmailEvent>;
