import * as z from 'zod';

import {
  BorrowerId,
  EscrowCompanyId,
  PaymentConfigId,
  PayoffId,
  PrincipalModificationId,
  QcDocumentId,
  WorkoutPlanId,
} from '../BrandedIds';
import {
  BorrowerUploadDocument,
  DefermentWorkoutPlanDocument,
  DelinquentInterestAppliedDocument,
  EscrowAnalysisDocument,
  Form1098Document,
  GoodbyeLetterDocument,
  LenderUploadDocument,
  MortgageeLetterDocument,
  PaidInFullStatementDocument,
  PartialPayoffConfirmationDocument,
  PayoffStatementDocument,
  PreActiveWelcomeLetterDocument,
  PrincipalModificationAppliedDocument,
  PrincipalModificationCancelledDocument,
} from '../documents/Templates';
import { EmailTemplateType } from '../emails/Templates';
import { LoanDocumentForm1098Copy } from '../loan/Document';
import { BorrowerAccessSaveInput } from '../loan/DocumentAccess';
import { Form1098Year } from '../qcDocument/documents/Form1098';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

const BaseSaveDocumentPdfEvent = BaseLoanEvent.extend({
  type: z.literal('saveDocumentPdf'),
  url: z.string(),
  version: z.number(),
  comment: z.string().optional(),
  noteflowDocumentId: z.string().optional(),
});

/* ONE OFF DOCUMENT TEMPLATES */
export const PreActiveWelcomeLetterSaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: PreActiveWelcomeLetterDocument,
});
export type PreActiveWelcomeLetterSaveDocumentPdfEvent = z.infer<typeof PreActiveWelcomeLetterSaveDocumentPdfEvent>;

export const MortgageeLetterSaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: MortgageeLetterDocument,
  escrowCompanyId: zodBrandedUuid<EscrowCompanyId>(),
});
export type MortgageeLetterSaveDocumentPdfEvent = z.infer<typeof MortgageeLetterSaveDocumentPdfEvent>;

const PayoffStatementSaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: PayoffStatementDocument,
  payoffId: zodBrandedUuid<PayoffId>(),
  borrowerNotifications: z.array(zodBrandedUuid<BorrowerId>()),
  isPartial: z.boolean().optional(),
});
export type PayoffStatementSaveDocumentPdfEvent = z.infer<typeof PayoffStatementSaveDocumentPdfEvent>;

const PaidInFullDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: PaidInFullStatementDocument,
  payoffId: zodBrandedUuid<PayoffId>(),
});

const PartialPayoffConfirmationDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: PartialPayoffConfirmationDocument,
  payoffId: zodBrandedUuid<PayoffId>(),
});
export const LenderUploadSaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: LenderUploadDocument,
  title: z.string(),
  borrowerAccess: BorrowerAccessSaveInput,
});
export type LenderUploadSaveDocumentPdfEvent = z.infer<typeof LenderUploadSaveDocumentPdfEvent>;

export const BorrowerUploadSaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: BorrowerUploadDocument,
  title: z.string(),
});
export type BorrowerUploadSaveDocumentPdfEvent = z.infer<typeof BorrowerUploadSaveDocumentPdfEvent>;

/* QC DOCS */
export const Form1098SaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: Form1098Document,
  qcDocumentId: zodBrandedUuid<QcDocumentId>(),
  isCorrected: z.boolean(),
  year: Form1098Year,
  copy: LoanDocumentForm1098Copy,
});
export type Form1098SaveDocumentPdfEvent = z.infer<typeof Form1098SaveDocumentPdfEvent>;

export const EscrowAnalysisSaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: EscrowAnalysisDocument,
  qcDocumentId: zodBrandedUuid<QcDocumentId>(),
  effectiveDate: zodDateOrString,
  isCorrected: z.boolean(),
});
export type EscrowAnalysisSaveDocumentPdfEvent = z.infer<typeof EscrowAnalysisSaveDocumentPdfEvent>;

export const DefermentWorkoutPlanSaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: DefermentWorkoutPlanDocument,
  borrowerIds: z.array(zodBrandedUuid<BorrowerId>()).optional(),
  workoutPlanId: zodBrandedUuid<WorkoutPlanId>(),
});

export type DefermentWorkoutPlanSaveDocumentPdfEvent = z.infer<typeof DefermentWorkoutPlanSaveDocumentPdfEvent>;

export const RepaymentWorkoutPlanSaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: z.literal('repaymentWorkoutPlan'),
  borrowerIds: z.array(zodBrandedUuid<BorrowerId>()).optional(),
  workoutPlanId: zodBrandedUuid<WorkoutPlanId>(),
});

export type RepaymentWorkoutPlanSaveDocumentPdfEvent = z.infer<typeof RepaymentWorkoutPlanSaveDocumentPdfEvent>;

export const ForbearanceWorkoutPlanSaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: z.literal('forbearanceWorkoutPlan'),
  borrowerIds: z.array(zodBrandedUuid<BorrowerId>()).optional(),
  workoutPlanId: zodBrandedUuid<WorkoutPlanId>(),
});

export type ForbearanceWorkoutPlanSaveDocumentPdfEvent = z.infer<typeof ForbearanceWorkoutPlanSaveDocumentPdfEvent>;

export const DelinquentInterestAppliedSaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: DelinquentInterestAppliedDocument,
  paymentConfigId: zodBrandedUuid<PaymentConfigId>(),
});

export type DelinquentInterestAppliedSaveDocumentPdfEvent = z.infer<
  typeof DelinquentInterestAppliedSaveDocumentPdfEvent
>;

export const PrincipalModificationAppliedSaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: PrincipalModificationAppliedDocument,
  principalModificationId: zodBrandedUuid<PrincipalModificationId>(),
});

export type PrincipalModificationAppliedSaveDocumentPdfEvent = z.infer<
  typeof PrincipalModificationAppliedSaveDocumentPdfEvent
>;

export const PrincipalModificationCancelledSaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: PrincipalModificationCancelledDocument,
  principalModificationId: zodBrandedUuid<PrincipalModificationId>(),
});

export type PrincipalModificationCancelledSaveDocumentPdfEvent = z.infer<
  typeof PrincipalModificationCancelledSaveDocumentPdfEvent
>;

/* EMAIL COPY TEMPLATES */
const GoodbyeSaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: GoodbyeLetterDocument, // This event already existed, but shouldnt be used anymore
});

export const EmailCopySaveDocumentPdfEvent = BaseSaveDocumentPdfEvent.extend({
  documentType: z.literal('emailCopy'),
  emailTemplateType: EmailTemplateType,
  emailTitle: z.string(),
});
export type EmailCopySaveDocumentPdfEvent = z.infer<typeof EmailCopySaveDocumentPdfEvent>;

/* --------------------- */
export const SaveDocumentPdfEvent = z.discriminatedUnion('documentType', [
  PreActiveWelcomeLetterSaveDocumentPdfEvent,
  MortgageeLetterSaveDocumentPdfEvent,
  PayoffStatementSaveDocumentPdfEvent,
  PaidInFullDocumentPdfEvent,
  PartialPayoffConfirmationDocumentPdfEvent,
  LenderUploadSaveDocumentPdfEvent,
  BorrowerUploadSaveDocumentPdfEvent,
  Form1098SaveDocumentPdfEvent,
  EscrowAnalysisSaveDocumentPdfEvent,
  DefermentWorkoutPlanSaveDocumentPdfEvent,
  RepaymentWorkoutPlanSaveDocumentPdfEvent,
  ForbearanceWorkoutPlanSaveDocumentPdfEvent,
  DelinquentInterestAppliedSaveDocumentPdfEvent,
  PrincipalModificationAppliedSaveDocumentPdfEvent,
  PrincipalModificationCancelledSaveDocumentPdfEvent,
  GoodbyeSaveDocumentPdfEvent,
  EmailCopySaveDocumentPdfEvent,
]);
export type SaveDocumentPdfEvent = z.infer<typeof SaveDocumentPdfEvent>;
