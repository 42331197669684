import { z } from 'zod';

const bankAccountNumberRegex = /^[0-9A-Za-z]{5,17}$/;

export const BankAccountNumber = z
  .string()
  .min(1, { message: 'Required' })
  .min(5, { message: 'Must be at least 5 characters' })
  .max(17, { message: 'Must be at most 17 characters' })
  .regex(bankAccountNumberRegex, { message: 'Must be between 5 and 17 alphanumeric characters' });
export type BankAccountNumber = z.infer<typeof BankAccountNumber>;

/**
 * Validate a routing number's check digit according to the ABA RTN check digit algorithm
 *
 * Per https://en.wikipedia.org/wiki/ABA_routing_transit_number#Check_digit
 *
 *     The ninth, check digit provides a checksum test using a position-weighted sum of each of the digits. [...]
 *     The following condition must hold:
 *
 *     (3(d1 + d4 + d7) + 7(d2 + d5 + d8) + (d3 + d6 + d9)) mod 10 = 0
 *
 * @param val - The routing number to validate
 * @returns Whether the routing number is valid (true or false)
 */
export const isRoutingNumberCheckDigitValid = (val: string): boolean => {
  // checking length here is possibly redundant when combined with other length validations but seems sensible since this
  // is exported (for testing) and somebody else could try to use it without also checking the length first.
  if (val.length !== 9) {
    return false;
  }

  const digits = val.split('').map((d) => +d);
  return (
    (3 * (digits[0] + digits[3] + digits[6]) +
      7 * (digits[1] + digits[4] + digits[7]) +
      1 * (digits[2] + digits[5] + digits[8])) %
      10 ===
    0
  );
};

export const BankRoutingNumber = z
  .string()
  .min(1, { message: 'Required' })
  .length(9, { message: 'Must be 9 digits' })
  .refine(isRoutingNumberCheckDigitValid, { message: 'Invalid routing number' });
export type BankRoutingNumber = z.infer<typeof BankRoutingNumber>;
