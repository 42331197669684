import * as z from 'zod';

import {
  CompanyId,
  CompanyReportId,
  EventId,
  InvestorReportingId,
  LoanId,
  RecurringCompanyReportId,
} from '../BrandedIds';
import { CompanyReportType } from '../CompanyReport';
import { LoanInvestorName } from '../loan/Loan';
import { zodBrandedUuid, zodDateOrString } from '../utils';
import { FannieMaeLar96 } from './FannieMae/FannieMaeLar96';
import { FreddieMacLLR } from './FreddieMac/FreddieMacLLR';

export const InvestorReportingReportType = z.enum([
  CompanyReportType.FANNIE_MAE_LAR_96_DAILY,
  CompanyReportType.FANNIE_MAE_LAR_96_MONTHLY,
  CompanyReportType.FANNIE_MAE_LAR_96_TRAILING,
  CompanyReportType.FREDDIE_MAC_LLR,
]);
export type InvestorReportingReportType = z.infer<typeof InvestorReportingReportType>;

export const InvestorReportingCompanyReportTypes: InvestorReportingReportType[] = [
  CompanyReportType.FANNIE_MAE_LAR_96_DAILY,
  CompanyReportType.FANNIE_MAE_LAR_96_MONTHLY,
  CompanyReportType.FANNIE_MAE_LAR_96_TRAILING,
  CompanyReportType.FREDDIE_MAC_LLR,
] as const;

export type FannieMaeLAR96ReportTypes =
  | CompanyReportType.FANNIE_MAE_LAR_96_DAILY
  | CompanyReportType.FANNIE_MAE_LAR_96_MONTHLY
  | CompanyReportType.FANNIE_MAE_LAR_96_TRAILING;

export const InvestorReportingReportTypeToInvestor: Record<InvestorReportingReportType, LoanInvestorName> = {
  [CompanyReportType.FANNIE_MAE_LAR_96_DAILY]: 'FANNIE_MAE',
  [CompanyReportType.FANNIE_MAE_LAR_96_MONTHLY]: 'FANNIE_MAE',
  [CompanyReportType.FANNIE_MAE_LAR_96_TRAILING]: 'FANNIE_MAE',
  [CompanyReportType.FREDDIE_MAC_LLR]: 'FREDDIE_MAC',
} as const;

const FannieMaeLar96DailyReportData = z.object({
  reportType: z.literal(CompanyReportType.FANNIE_MAE_LAR_96_DAILY),
  data: FannieMaeLar96,
});

const FannieMaeLar96MonthlyReportData = z.object({
  reportType: z.literal(CompanyReportType.FANNIE_MAE_LAR_96_MONTHLY),
  data: FannieMaeLar96,
});

const FannieMaeLar96TrailingReportData = z.object({
  reportType: z.literal(CompanyReportType.FANNIE_MAE_LAR_96_TRAILING),
  data: FannieMaeLar96,
});

const FreddieMacLLRReportData = z.object({
  reportType: z.literal(CompanyReportType.FREDDIE_MAC_LLR),
  data: FreddieMacLLR,
});

export const InvestorReportingData = z.discriminatedUnion('reportType', [
  FannieMaeLar96DailyReportData,
  FannieMaeLar96MonthlyReportData,
  FannieMaeLar96TrailingReportData,
  FreddieMacLLRReportData,
]);
export type InvestorReportingData = z.infer<typeof InvestorReportingData>;

export const InvestorReporting = z.object({
  id: zodBrandedUuid<InvestorReportingId>(),
  loanId: zodBrandedUuid<LoanId>(),
  loanSnapshotVersion: z.number(),
  companyId: zodBrandedUuid<CompanyId>(),
  eventId: zodBrandedUuid<EventId>().optional(),
  recurringCompanyReportId: zodBrandedUuid<RecurringCompanyReportId>().optional(),
  companyReportIds: zodBrandedUuid<CompanyReportId>().array(),
  investor: LoanInvestorName,
  reportType: InvestorReportingReportType,
  data: InvestorReportingData,
  createdAt: zodDateOrString,
});
export type InvestorReporting = z.infer<typeof InvestorReporting>;
