import { z } from 'zod';

import { CompanyId, EventId } from '../BrandedIds';
import { CompanyReportType } from '../CompanyReport';
import { InvestorReportingReportType } from '../investorReporting';
import { zodBrandedUuid } from '../utils';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const UpsertInvestorReportingSideEffectContext = BaseLoanIdSideEffect.extend({
  reportType: InvestorReportingReportType.exclude([CompanyReportType.FREDDIE_MAC_LLR]),
  eventId: zodBrandedUuid<EventId>(),
  companyId: zodBrandedUuid<CompanyId>(),
});

export const UpsertInvestorReportingSideEffect = UpsertInvestorReportingSideEffectContext.extend({
  type: z.literal('upsertInvestorReporting'),
});
export type UpsertInvestorReportingSideEffect = z.infer<typeof UpsertInvestorReportingSideEffect>;
