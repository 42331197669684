import * as z from 'zod';

export const FreddieMacLLRV1 = z.object({
  version: z.literal('1'),
  losId: z.string(), // Appears as "Servicer Loan Number" in Freddie documentation
  freddieMacLoanNumber: z.string(),
  lastPaymentReceivedDate: z.string().optional(), // in the format YYYY-MM-DD. todo: upgrade zod so we can use z.string().date()
  lpiDate: z.string().optional(), // same as above
  unpaidPrincipalBalance: z.number(),
  // Interest due using the Accounting Net Yield (ANY) method, meaning the interest due after the servicing
  // portion is taken out. From Freddie's docs:
  // > The ANY rate equals the note rate less the servicing spread. For example, if the note rate is 5 percent,
  // > and the servicing spread is 0.25 percent, the accounting net yield rate equals 4.75 percent.
  interestDue: z.number(),
  // From Freddie's docs:
  // > For Mortgages accounted for under the net yield method, report the Freddie Mac share of principal payments,
  // > including prepaid principal, curtailments, reduction of negative amortization, payoffs and foreclosure sale
  // > proceeds.
  principalDue: z.number(),
  // Exception codes are listed here: https://guide.freddiemac.com/app/guide/exhibit/60
  exceptionCode: z.enum(['40', '50', '60', '61', '65', '66', '67', '70', '71', '72', '73', '80', '91']).optional(),
  exceptionDate: z.string().optional(),
  noteholder: z.enum(['0', '1', '2', '']),
  // The current annual rate of interest the Borrower is paying on the Mortgage
  mortgageNoteRate: z.number(),
  // The principal and interest (P&I) portion of the Borrower's regular installment.
  // (Note: 100% of the P&I amount should be entered in this field, not Freddie Mac's share.)
  mortgagePrincipalInterest: z.number(),

  // Deferment-related fields

  // For Mortgages with a partial principal forbearance, the amount of the interest-bearing unpaid principal
  // balance (the amortizing principal balance) as of the end of the Accounting Cycle.
  interestBearingUPB: z.number().optional(),
  // For Mortgages with a partial principal forbearance, the amount of the deferred (non-interest-bearing) unpaid
  // principal balance as of the end of the Accounting Cycle.
  deferredUPB: z.number().optional(),
  // The amount of any principal curtailment applied to the Deferred UPB during the Accounting Cycle.
  deferredPrincipalCurtailmentAmount: z.number().optional(),

  // HAMP fields

  // The amount of any Borrower incentive payments paid by Freddie Mac and applied to the Interest-Bearing UPB
  // of the Mortgage during the Accounting Cycle.
  borrowerIncentiveCurtailmentAmount: z.number().optional(),
});
export type FreddieMacLLRV1 = z.infer<typeof FreddieMacLLRV1>;

export const FreddieMacLLR = FreddieMacLLRV1;
export type FreddieMacLLR = z.infer<typeof FreddieMacLLR>;
// Make `FreddieMacLLRReport` a discriminated union on 'version' when changes happen
// export const FreddieMacLLRV2 = z.object({
//   version: z.literal('2'),
//   foo: z.string(),
// });
// export const FreddieMacLLR = z.discriminatedUnion('version', [FreddieMacLLRV1, FreddieMacLLRV02012025]);
