import { z } from 'zod';

import { BorrowerId, DocumentId, PaymentId, PayoffId, PrincipalModificationId } from '../BrandedIds';
import { EmailAttachment } from '../EmailAttachment';
import {
  BaseEmailTemplateType,
  BaseEmailWithAttachmentTemplateType,
  DocumentEmailTemplateType,
  GoodbyeBeforeWelcome,
  GoodbyeEmail,
  PartialPayoffConfirmationEmail,
  PaymentEmailTemplateType,
  PaymentFailedAutopayEmail,
  PrincipalModificationAppliedEmail,
  PrincipalModificationCancelledEmail,
  RescindGoodbyeEmail,
  UpdateAutopayEmailTemplateType,
} from '../emails/Templates';
import { SendEmailOptions } from '../events/SendEmailEvent';
import { LoanStatus } from '../loan/LoanStatus';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

const SendBaseEmailSideEffectContext = BaseLoanIdSideEffect.extend({
  borrowerIds: z.array(zodBrandedUuid<BorrowerId>()),
  emailTemplate: BaseEmailTemplateType,
  opts: SendEmailOptions,
});
export const SendEmailSideEffect = SendBaseEmailSideEffectContext.extend({
  type: z.literal('sendEmail'),
});

const SendRescindGoodbyeEmailSideEffectContext = SendBaseEmailSideEffectContext.extend({
  emailTemplate: RescindGoodbyeEmail,
  transferEffectiveDate: z.string(),
  newLenderName: z.string(),
  newLoanStatus: LoanStatus,
});
const SendRescindGoodbyeEmailSideEffect = SendRescindGoodbyeEmailSideEffectContext.extend({
  type: z.literal('sendEmail'),
});
export type SendRescindGoodbyeEmailSideEffect = z.infer<typeof SendRescindGoodbyeEmailSideEffect>;

const SendGoodbyeEmailSideEffectContext = SendBaseEmailSideEffectContext.extend({
  emailTemplate: z.union([GoodbyeEmail, GoodbyeBeforeWelcome]),
  isUpdate: z.boolean().optional(),
});
const SendGoodbyeEmailSideEffect = SendGoodbyeEmailSideEffectContext.extend({
  type: z.literal('sendEmail'),
});
export type SendGoodbyeEmailSideEffect = z.infer<typeof SendGoodbyeEmailSideEffect>;

const SendPaymentEmailSideEffectContext = SendBaseEmailSideEffectContext.extend({
  emailTemplate: PaymentEmailTemplateType,
  paymentId: zodBrandedUuid<PaymentId>(),
});
export const SendPaymentEmailSideEffect = SendPaymentEmailSideEffectContext.extend({
  type: z.literal('sendEmail'),
});
export type SendPaymentEmailSideEffect = z.infer<typeof SendPaymentEmailSideEffect>;

const SendPaymentFailedEmailSideEffectContext = SendBaseEmailSideEffectContext.extend({
  emailTemplate: PaymentFailedAutopayEmail,
  paymentId: zodBrandedUuid<PaymentId>().optional(),
  failureReason: z.string().optional(),
});
export const SendPaymentFailedEmailSideEffect = SendPaymentFailedEmailSideEffectContext.extend({
  type: z.literal('sendEmail'),
});
export type SendPaymentFailedEmailSideEffect = z.infer<typeof SendPaymentFailedEmailSideEffect>;

const DwollaUpdateAutopayEmailSideEffectContext = SendBaseEmailSideEffectContext.extend({
  paymentProvider: z.literal('dwolla'),
  emailTemplate: UpdateAutopayEmailTemplateType,
  autopayBankUrl: z.string(),
});
const UsioUpdateAutopayEmailSideEffectContext = SendBaseEmailSideEffectContext.extend({
  paymentProvider: z.literal('usio'),
  emailTemplate: UpdateAutopayEmailTemplateType,
  confirmationId: z.string(),
});
export const SendUpdateAutopayEmailSideEffectContext = z.discriminatedUnion('paymentProvider', [
  DwollaUpdateAutopayEmailSideEffectContext,
  UsioUpdateAutopayEmailSideEffectContext,
]);
const DwollaUpdateAutopayEmailSideEffect = DwollaUpdateAutopayEmailSideEffectContext.extend({
  type: z.literal('sendEmail'),
});
const UsioUpdateAutopayEmailSideEffect = UsioUpdateAutopayEmailSideEffectContext.extend({
  type: z.literal('sendEmail'),
});
export const SendUpdateAutopayEmailSideEffect = z.discriminatedUnion('paymentProvider', [
  DwollaUpdateAutopayEmailSideEffect,
  UsioUpdateAutopayEmailSideEffect,
]);
export type SendUpdateAutopayEmailSideEffect = z.infer<typeof SendUpdateAutopayEmailSideEffect>;

const SendDocumentEmailSideEffectContext = SendBaseEmailSideEffectContext.extend({
  emailTemplate: DocumentEmailTemplateType,
  documentId: zodBrandedUuid<DocumentId>(),
});
export const SendDocumentEmailSideEffect = SendDocumentEmailSideEffectContext.extend({
  type: z.literal('sendEmail'),
});
export type SendDocumentEmailSideEffect = z.infer<typeof SendDocumentEmailSideEffect>;

/* ATTACHEMENT EMAILS */
const SendEmailWithAttachmentSideEffectContext = SendBaseEmailSideEffectContext.extend({
  emailTemplate: BaseEmailWithAttachmentTemplateType,
  attachments: z.array(EmailAttachment),
});
export const SendEmailWithAttachmentSideEffect = SendEmailWithAttachmentSideEffectContext.extend({
  type: z.literal('sendEmail'),
});
export type SendEmailWithAttachmentSideEffect = z.infer<typeof SendEmailWithAttachmentSideEffect>;

const SendPartialPayoffConfirmationEmailSideEffectContext = SendBaseEmailSideEffectContext.extend({
  emailTemplate: PartialPayoffConfirmationEmail,
  payoffId: zodBrandedUuid<PayoffId>(),
});
export const SendPartialPayoffConfirmationEmailSideEffect = SendPartialPayoffConfirmationEmailSideEffectContext.extend({
  type: z.literal('sendEmail'),
});
export type SendPartialPayoffConfirmationEmailSideEffect = z.infer<typeof SendPartialPayoffConfirmationEmailSideEffect>;

const SendPrincipalModificationAppliedEmailSideEffectContext = SendBaseEmailSideEffectContext.extend({
  emailTemplate: PrincipalModificationAppliedEmail,
  principalModificationId: zodBrandedUuid<PrincipalModificationId>(),
});
export const SendPrincipalModificationAppliedEmailSideEffect =
  SendPrincipalModificationAppliedEmailSideEffectContext.extend({
    type: z.literal('sendEmail'),
  });

export type SendPrincipalModificationAppliedEmailSideEffect = z.infer<
  typeof SendPrincipalModificationAppliedEmailSideEffect
>;

const SendPrincipalModificationCancelledEmailSideEffectContext = SendBaseEmailSideEffectContext.extend({
  emailTemplate: PrincipalModificationCancelledEmail,
  principalModificationId: zodBrandedUuid<PrincipalModificationId>(),
});

export const SendPrincipalModificationCancelledEmailSideEffect =
  SendPrincipalModificationCancelledEmailSideEffectContext.extend({
    type: z.literal('sendEmail'),
  });

export type SendPrincipalModificationCancelledEmailSideEffect = z.infer<
  typeof SendPrincipalModificationCancelledEmailSideEffect
>;

export const SendEmailSideEffectContext = z.union([
  SendBaseEmailSideEffectContext,
  SendPaymentEmailSideEffectContext,
  SendPaymentFailedEmailSideEffectContext,
  SendRescindGoodbyeEmailSideEffectContext,
  SendUpdateAutopayEmailSideEffectContext,
  SendEmailWithAttachmentSideEffectContext,
  SendDocumentEmailSideEffectContext,
  SendGoodbyeEmailSideEffectContext,
  SendPartialPayoffConfirmationEmailSideEffectContext,
  SendPrincipalModificationAppliedEmailSideEffectContext,
  SendPrincipalModificationCancelledEmailSideEffectContext,
]);
export type SendEmailSideEffect = z.infer<typeof SendEmailSideEffect>;
