
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Attribution": [
      "DelinquentInterestAttribution",
      "DrawInterestAttribution",
      "PartialPayoffInterestAttribution",
      "StandardInterestAttribution"
    ],
    "AuthorizedContact": [
      "BuilderAuthorizedContact",
      "ThirdPartyAuthorizedContact"
    ],
    "AutopaySettings": [
      "DwollaAutopaySettings",
      "UsioAutopaySettings"
    ],
    "Charge": [
      "Advance",
      "Fee"
    ],
    "Document": [
      "DefermentWorkoutPlanDocument",
      "DelinquentInterestAppliedDocument",
      "EscrowAnalysisDocument",
      "ForbearanceWorkoutPlanDocument",
      "Form1098Document",
      "GeneralDocument",
      "PayoffDocument",
      "PrincipalModificationAppliedDocument",
      "PrincipalModificationCancelledDocument",
      "RepaymentWorkoutPlanDocument",
      "UploadDocument"
    ],
    "EscrowDue": [
      "InsuranceEscrowDue",
      "MortgageInsuranceEscrowDue",
      "TaxEscrowDue"
    ],
    "EscrowSetting": [
      "InsuranceEscrowSetting",
      "MortgageInsuranceEscrowSetting",
      "TaxEscrowSetting"
    ],
    "PaidOffRecord": [
      "TrackedPaidOffRecord",
      "UntrackedPaidOffRecord"
    ],
    "Payer": [
      "AuthorizedContactPayer",
      "BorrowerPayer",
      "ThirdPartyPayer"
    ],
    "PaymentToPaymentDue": [
      "AdvanceBreakdown",
      "BalanceReallocationBreakdown",
      "FeeBreakdown",
      "PayoffBreakdown",
      "PrincipalPrepaymentBreakdown",
      "StatementBreakdown",
      "SuspenseBreakdown",
      "ThirdPartyBreakdown"
    ],
    "WorkoutPlan": [
      "DefermentWorkoutPlan",
      "ForbearanceWorkoutPlan",
      "RepaymentWorkoutPlan"
    ]
  }
};
      export default result;
    